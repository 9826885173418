<template>
  <div class="options-menu">
    <div class="options-menu-header">
        <h1 v-if="!is_catalog_signs">שילוט מבצעים בחירה</h1>
        <h1 v-else>שילוט קטלוגי לבחירה</h1>
    </div>
    <div class="options-menu-content">
        <div class="options-menu-content-option">
            <div class="options-menu-content-option-header">
                <h2>שישיית שלטים קטנים</h2>
            </div>
            <div @click="$emit('blank_page','BlankPageSalesSix',pages_data.page_option_six)" ref="a4_page" class="options-menu-content-option-content six">
                <div class="sishi">
                    1/6
                </div>
                <div class="sishi">
                    1/6
                </div>
                <div class="sishi">
                    1/6
                </div>
                <div class="sishi">
                    1/6
                </div>
                <div class="sishi">
                    1/6
                </div>
                <div class="sishi">
                    1/6
                </div>
            </div>
        </div>
        <div class="options-menu-content-option">
            <div class="options-menu-content-option-header">
                <h2>עשיריית שלטים מיני</h2>
            </div>
            <div @click="$emit('blank_page','BlankPageSalesEight',pages_data.page_option_one)" ref="a4_page" class="options-menu-content-option-content eight">
                <div class="ten">
                    1/10
                </div>
                <div class="ten">
                    1/10
                </div>
                <div class="ten">
                    1/10
                </div>
                <div class="ten">
                    1/10
                </div>
                <div class="ten">
                    1/10
                </div>
                <div class="ten">
                    1/10
                </div>
                <div class="ten">
                    1/10
                </div>
                <div class="ten">
                    1/10
                </div>
                <div class="ten">
                    1/10
                </div>
                <div class="ten">
                    1/10
                </div>
            </div>
        </div>
        <div class="options-menu-content-option">
            <div class="options-menu-content-option-header">
                <h2>שימיניות שלטים מיני</h2>
            </div>
            <div @click="$emit('blank_page','BlankPageSalesShmone',pages_data.page_option_one)" ref="a4_page" class="options-menu-content-option-content eight">
                <div class="shmini">
                    1/8
                </div>
                <div class="shmini">
                    1/8
                </div>
                <div class="shmini">
                    1/8
                </div>
                <div class="shmini">
                    1/8
                </div>
                <div class="shmini">
                    1/8
                </div>
                <div class="shmini">
                    1/8
                </div>
                <div class="shmini">
                    1/8
                </div>
                <div class="shmini">
                    1/8
                </div>
            </div>
        </div>
        <div class="options-menu-content-option">
            <div class="options-menu-content-option-header">
                <h2>שלישיית שלטים</h2>
            </div>
            <div @click="$emit('blank_page','BlankPageSales',pages_data.page_option_one)" ref="a4_page" class="options-menu-content-option-content">
                <div class="three">
                    1/3
                </div>
                <div class="three">
                    1/3
                </div>
                <div class="three">
                    1/3
                </div>
            </div>
        </div>
        <div class="options-menu-content-option">
            <div class="options-menu-content-option-header">
                <h2>רביעיית שלטים</h2>
            </div>
            <div @click="$emit('blank_page','BlankPageSalesFour',pages_data.page_option_one)" ref="a4_page" class="options-menu-content-option-content">
                <div class="four">
                    1/4
                </div>
                <div class="four">
                    1/4
                </div>
                <div class="four">
                    1/4
                </div>
                <div class="four">
                    1/4
                </div>
            </div>
        </div>
        <div class="options-menu-content-option">
            <div class="options-menu-content-option-header">
                <h2>2 שלטים</h2>
            </div>
            <div ref="a4_page" @click="$emit('blank_page','BlankPageSalesTwo',pages_data.page_option_two)" class="options-menu-content-option-content">
                <div class="two">
                    1/2
                </div>
                <div class="two">
                    1/2
                </div>
            </div>
        </div>
        <div class="options-menu-content-option">
            <div class="options-menu-content-option-header">
                <h2>שלט שלם</h2>
            </div>
            <div ref="a4_page" @click="$emit('blank_page','BlankPageSalesOne',pages_data.page_option_three)" class="options-menu-content-option-content one">
                A4
            </div>
        </div>
    </div>
    <div v-if="show_multiple_choice" class="multiple-choise slide-in-top">
        <ShilutDb @back="handle_back_multiple_choice" @reload_signs="handle_reload_signs" />
    </div>
    <div v-if="show_multiple_deals_choice" class="multiple-choise slide-in-top">
        <ShilutDealsDb @back="handle_back_multiple_deals_choice" @reload_signs="handle_reload_signs" />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, onBeforeUnmount, onMounted, ref } from '@vue/runtime-core'
import {is_catalog_signs} from './Deals/Scripts/store'
export default {
    emits:['blank_page','signs_selected','deals_selected'],
    components:{
        ShilutDb:defineAsyncComponent(()=>import('./OptionMenu/ShilutDb.vue')),
        ShilutDealsDb:defineAsyncComponent(()=>import('./OptionMenu/ShilutDealsDb.vue'))
    },
    setup(_,{emit}){
        const show_multiple_choice = ref(false)
        const show_multiple_deals_choice = ref(false)
        
        const blank_page_type_selected = ref('')

        const a4_page = ref()

        const pages_data = ref({
            page_option_one:1,
            page_option_two:1,
            page_option_three:1,
            page_option_six:1,
        })
        
        const handle_reload_signs = (selected_signs) => {
            emit('signs_selected',blank_page_type_selected.value,selected_signs)
        }

        const handle_multiple_choise = (blank_page_type) => {
            blank_page_type_selected.value = blank_page_type
            show_multiple_choice.value = true
        }

        const handle_multiple_deals_choise = (blank_page_type) => {
            blank_page_type_selected.value = blank_page_type
            show_multiple_deals_choice.value = true
        }   

        const handle_back_multiple_choice = () =>{
            blank_page_type_selected.value = ''
            show_multiple_choice.value = false
        }

        const handle_back_multiple_deals_choice = () =>{
            blank_page_type_selected.value = ''
            show_multiple_deals_choice.value = false
        }

        const resize = function() {
                const res = a4_page.value.offsetHeight / 1.414
                document.documentElement.style.setProperty('--w', `${res}px`);
        }

        onMounted(()=>{
            window.addEventListener("resize", resize);
            resize()
        })

        onBeforeUnmount(()=>{
            window.removeEventListener("resize", resize);
        })
        
        return{
            handle_multiple_choise,
            handle_back_multiple_choice,
            handle_reload_signs,
            handle_multiple_deals_choise,
            handle_back_multiple_deals_choice,
            show_multiple_deals_choice,
            a4_page,
            pages_data,
            show_multiple_choice,
            is_catalog_signs,
        }
    }
}
</script>

<style scoped>
    .options-menu{
        width: 100%;
        height: 100%;
        color: #fff;
        padding: 0 5px;
    }
    .options-menu-header{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--secondary);
        border-radius: 5px;
    }
    .options-menu-content{
        width: 100%;
        height: calc(100% - 50px);
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-auto-rows: 200px;
        grid-gap: 5px;
        padding: 5px 0;
        overflow-y: auto;
    }
    .pages,.multiple-choise-btn,.multiple-deal-choise-btn{
        position: absolute;
        top: 10px;
        left: 5px;
        z-index: 19;
        color: #fff;
    }
    .multiple-choise-btn,.multiple-deal-choise-btn{
        left: unset;
        right: 5px;
        width: max-content;
        height: 50px;
        background: var(--secondary);
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        border-radius: 10px;
        cursor: pointer;
        font-size: 15px;
        font-weight: 500;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.753);
        padding: 0 5px;
    }
    .multiple-deal-choise-btn{
        top: unset;
        bottom: 10px;
    }
    .multiple-choise-btn:hover,.multiple-deal-choise-btn:hover{
        color: var(--warning);
    }
    .options-menu-content-option{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        border: 1px solid #fff;
        color: #fff;
    }  
    .options-menu-content-option-header{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .options-menu-content-option-content{
        margin: 0 auto;
        width: var(--w);
        height: calc(100% - 45px);
        border: 1px dashed #fff;
        cursor: pointer;
    }
    .options-menu-content-option-content:hover,.options-menu-content-option-content:hover *{
        color: var(--warning);
        border-color: var(--warning) !important;
    }

    /* שמיניות ועשיריות שלטים */

    .options-menu-content-option-content.eight{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    .options-menu-content-option-content.eight > .ten,.options-menu-content-option-content.eight > .shmini{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .options-menu-content-option-content > .ten:nth-child(-n+8),.options-menu-content-option-content > .shmini:nth-child(-n+6) {
        border-bottom: 1px solid #fff;
    }
    .options-menu-content-option-content > .ten:nth-child(odd),.options-menu-content-option-content > .shmini:nth-child(odd) {
        border-left: 1px solid #fff;
    }
    /* ================ */

    /* שיישית שלטים */
    .options-menu-content-option-content.six{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    .options-menu-content-option-content.six > .sishi{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .options-menu-content-option-content > .sishi:nth-child(-n+4) {
        border-bottom: 1px solid #fff;
    }
    .options-menu-content-option-content > .sishi:nth-child(odd) {
        border-left: 1px solid #fff;
    }
    /* ================ */

    /* שלישייה ושני שלטים */
    .options-menu-content-option-content .three,.options-menu-content-option-content .two,.options-menu-content-option-content .four{
        width: 100%;
        height: calc(100% / 3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .options-menu-content-option-content .two{
        height: calc(100% / 2);
    }
    .options-menu-content-option-content .four{
        height: calc(100% / 4);
    }
    .options-menu-content-option-content .three:not(:last-child),
    .options-menu-content-option-content .two:not(:last-child),
    .options-menu-content-option-content .four:not(:last-child)
    {
        border-bottom: 1px solid #fff;
    }
    /* ================ */

    /* שלט אחד */
    .options-menu-content-option-content.one{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    /* ================ */
    .multiple-choise{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background: rgba(0, 0, 0, 0.9);
    }
    .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
</style>